import React, { ReactElement, ReactNode } from 'react';
import './ContentItem.scss';

interface Props {
	children: ReactNode;
}

export default function ContentItem({ children }: Props): ReactElement {
	return <div className="content-item">{children}</div>;
}
