import React, { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorPage from './views/Error/Error';
import MediaItem from './views/MediaItem/MediaItem';

export default function AppRoutes(): ReactElement {
	return (
		<Routes>
			<Route element={<MediaItem type="report" />} path="/:encryptedParams" />
			<Route element={<MediaItem type="alert" />} path="/m/:encryptedParams" />
			<Route element={<MediaItem type="report" />} path="/:uuid/:itemId" />
			<Route element={<MediaItem type="mentions" />} path="/m/:uuid/:itemId" />
			<Route element={<MediaItem type="spike" />} path="/s/:uuid/:itemId" />
			<Route element={<ErrorPage />} path="/error/:code" />
			<Route element={<Navigate to="/error/404" replace />} path="*" />
		</Routes>
	);
}
