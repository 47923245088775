import React, { ReactElement } from 'react';
import { useAppSelector } from 'store/hooks';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/src/arrays';
import './Topics.scss';

export default function Topics(): ReactElement {
	const { mediaItem } = useAppSelector((state) => state.mediaItem);

	if (arrayIsNullOrEmpty(mediaItem?.topics)) {
		return <></>;
	}

	return (
		<div className="topics">
			<Typography variant="subtitle">Topics</Typography>
			{mediaItem?.topics?.map((topic: string) => (
				<p data-testid="topic" key={topic}>
					{topic}
				</p>
			))}
		</div>
	);
}
