import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action } from '@reduxjs/toolkit';
import { RootState } from 'Types';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

export type AppThunkAction = ThunkAction<void, RootState, unknown, Action>;
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, Action>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
