import React, { ReactElement } from 'react';
import ContentItem from 'components/ContentItem';
import DateTime from 'components/DateTime';
import KeywordTags from 'components/KeywordTags';
import SyndicationList from 'components/SyndicationList';
import Title from 'components/Title';
import Topics from 'components/Topics';
import { Theme, useMediaQuery } from '@mui/material';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import './Content.scss';

export default (): ReactElement => {
	const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

	return (
		<Grid item xs={12} sm={6} className="content">
			{isSmUp && (
				<ContentItem>
					<Title />
				</ContentItem>
			)}

			<ContentItem>
				<DateTime />
			</ContentItem>

			<ContentItem>
				<KeywordTags />
			</ContentItem>

			<ContentItem>
				<Topics />
			</ContentItem>

			<ContentItem>
				<SyndicationList />
			</ContentItem>
		</Grid>
	);
};
