import MediaItem, { KeywordMatches, Syndication } from 'types/mediaItem';
import { deserializeSyndicationInformation } from '@truescope-web/utils/src/mediaItem';
import { determineShortName, joinDefined } from '@truescope-web/utils/src/strings';

export const deserializeMediaItem = (item: MediaItem): MediaItem => {
	const mediaItem = { ...item };

	const state: Partial<MediaItem> = {
		...mediaItem,
		short_name: determineShortName(mediaItem.source_name),
		source_source_url: mediaItem.source_url,
		source_media_type: mediaItem.media_type,
		syndicated_items: mediaItem?.syndications?.map((syndicatedItem: Syndication) => ({
			...syndicatedItem,
			source_id: syndicatedItem.item_url, // used as the key
			url: syndicatedItem.item_url,
			profile_image_url: syndicatedItem.source_profile_image,
		})),
		keywords_regex: joinDefined(
			mediaItem?.keyword_matches?.map(({ keyword }: KeywordMatches) => `\\b${keyword.replace(/[()]/g, ' ')}\\b`),
			'|'
		),
	};

	const syndicationInformation = deserializeSyndicationInformation(state);

	state.syndicated_count = syndicationInformation?.other_syndicated_items?.length;
	delete state.syndications;
	delete state.syndicated_items;

	return { ...state, ...syndicationInformation };
};

export default deserializeMediaItem;
