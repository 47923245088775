import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import { createTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import App from './App';
import config from './config.json';
import reportWebVitals from './reportWebVitals';
import store from './store/store';

declare module '@mui/styles' {
	type DefaultTheme = Theme;
}

axios.defaults.baseURL = config.api.client.baseUrl;

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 800,
			md: 992,
			lg: 1200,
			xl: 1920,
		},
	},
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<Router>
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</Provider>
		</Router>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
