import React, { MouseEvent, ReactElement, useCallback, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { BsChevronDown, BsCloudDownload } from 'react-icons/bs';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Button, List, ListItem, Popover } from '@mui/material';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import { useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import DownloadBroadcastPopover, {
	TVEyesDownloadRequestParameters,
} from '@truescope-web/react/lib/components/widgets/DownloadBroadcastPopover';
import { isTvEyesItemValid } from '@truescope-web/utils/lib/suppliers';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/src/arrays';
import { isNullOrUndefined } from '@truescope-web/utils/src/objects';
import { stringIsNullOrEmpty } from '@truescope-web/utils/src/strings';
import './BroadcastViewer.scss';

/**
 * Options for the 'Rewind'  button - all values represent seconds
 */
const rewindTimes = [15, 30, 45, 60, 90, 120];

export default (): ReactElement => {
	const dispatch = useAppDispatch();
	const { mediaItem, isLoading } = useAppSelector((state) => state.mediaItem);
	const [mediaPlayerUrl, setMediaPlayerUrl] = useState('');
	const [mediaBody, setMediaBody] = useState('');
	const [rewindAnchorEl, setRewindAnchorEl] = useState<null | HTMLButtonElement>();
	const [startTimeOffset, setStartTimeOffset] = useState(0);
	const [stationId, setStationId] = useState(null);
	const [isError, setIsError] = useState(false);
	const { showSnackbar } = useSnackbar();

	const fetchBroadcastData = useCallback(async () => {
		try {
			const { data } = await axios.post('viewer/v1/broadcast', {
				stationId: mediaItem.supplier_info_source_keys[0],
				startDateTime: mediaItem.item_date,
				playStartDateTime: startTimeOffset > 0 ? null : mediaItem.item_date,
				keywordsToHighlight: mediaItem.keywords_regex,
				startTimeOffset,
				itemId: mediaItem.item_id,
			});
			const { mediaview_url: mediaUrl, body } = data;
			setMediaPlayerUrl(mediaUrl);
			setMediaBody(body);
		} catch (_e) {
			setIsError(true);
		}
	}, [mediaItem, startTimeOffset, setMediaPlayerUrl, setMediaBody, setIsError]);

	React.useEffect(() => {
		setIsError(false);

		if (arrayIsNullOrEmpty(mediaItem.supplier_info_source_keys) || isNullOrUndefined(mediaItem.supplier_info_source_keys[0])) {
			setIsError(true);
			return;
		}

		setStationId(mediaItem.supplier_info_source_keys[0]);

		fetchBroadcastData();
	}, [dispatch, mediaItem, fetchBroadcastData]);

	const handleDownloadClick = async (event: unknown, downloadRequest: TVEyesDownloadRequestParameters) => {
		if (!mediaItem.canDownload) {
			return;
		}

		await axios
			.post('tveyes-download/v1', {
				...downloadRequest,
				stationId,
				workspaceId: mediaItem.workspace_id,
			})
			.then(({ data }) => {
				if (!stringIsNullOrEmpty(data.message)) {
					throw new Error(data.message);
				}
				showSnackbar(`Download request successfully sent. A link to the file will be sent to ${data.recipientEmail}`, 'success');
			})
			.catch((e) => {
				showSnackbar(`Download failed, ${e.message}`, 'error');
			});
	};

	const closeAllPopupMenus = () => {
		setRewindAnchorEl(null);
	};

	const handleRewindAmountClick = (offsetSeconds: number) => {
		setStartTimeOffset(startTimeOffset + offsetSeconds);
		closeAllPopupMenus();
	};

	const handleRewindButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
		closeAllPopupMenus();
		setRewindAnchorEl(event.currentTarget);
	};

	if (isLoading) {
		return <SkeletonWrapper />;
	}

	if (isError) {
		return (
			<section className="broadcast">
				<Typography variant="h3">Something went wrong</Typography>
				<Typography variant="subtitle">Unable to load the media player</Typography>
			</section>
		);
	}

	const renderRewindPopover = () => {
		const open = !isNullOrUndefined(rewindAnchorEl);

		return (
			<Popover className="media-control-menu" anchorEl={rewindAnchorEl} keepMounted open={open} onClose={closeAllPopupMenus}>
				<>
					<Typography className="header" id="media-control-subheader" variant="subtitle">
						REWIND START TIME
					</Typography>
					<List aria-labelledby="media-control-subheader">
						{rewindTimes.map((x) => (
							<ListItem button key={x} onClick={() => handleRewindAmountClick(x)}>
								{x}
								seconds
							</ListItem>
						))}
					</List>
				</>
			</Popover>
		);
	};

	const handleRenderDownloadButton = (label: string, onClick: (event: unknown) => void) => {
		return (
			<Button className="action-button" onClick={onClick} startIcon={<BsCloudDownload />} endIcon={<BsChevronDown />}>
				{label}
			</Button>
		);
	};

	return (
		<section className="broadcast">
			{isTvEyesItemValid(mediaItem.item_date) ? (
				<>
					<div className="embed-responsive">
						<iframe title="media-viewer" name="media-viewer" frameBorder="0" allowFullScreen src={mediaPlayerUrl} />
					</div>
					<Inline className="broadcast__options" horizontalAlignment={horizontalAlignment.left}>
						{mediaItem.canDownload && (
							<div data-testid="download">
								<DownloadBroadcastPopover
									mediaItem={{
										item_id: mediaItem.item_id,
										source_media_type: mediaItem.source_media_type,
										item_date: mediaItem.item_date,
									}}
									onDownload={handleDownloadClick}
									renderButton={handleRenderDownloadButton}
									startTimeOffsetSeconds={startTimeOffset}
								/>
							</div>
						)}
						<Button className="action-button" onClick={handleRewindButtonClick} endIcon={<BsChevronDown />}>
							Rewind
						</Button>
					</Inline>
					{renderRewindPopover()}
				</>
			) : (
				<Highlighter
					className="highlighter"
					highlightClassName="heading-colour__highlight"
					searchWords={[mediaItem.keywords_regex]}
					textToHighlight={mediaBody}
				/>
			)}
		</section>
	);
};
