import React, { ReactElement } from 'react';
import Routes from 'Routes';
import { SnackbarProvider } from '@truescope-web/react/lib/components/modal/Snackbar';
import './App.scss';

const App = (): ReactElement => (
	<div className="app">
		<SnackbarProvider>
			<Routes />
		</SnackbarProvider>
	</div>
);

export default App;
