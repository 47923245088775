import React, { ReactElement } from 'react';
import { useAppSelector } from 'store/hooks';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Avatar from '@truescope-web/react/lib/components/widgets/Avatar';
import ImageBadge from '@truescope-web/react/lib/components/widgets/ImageBadge';
import { getMediaTypeIcon } from '@truescope-web/react/lib/utils/mediaTypes';
import { getHostedIconUrl } from '@truescope-web/utils/src/mediaItem';
import { joinDefined, stringIsNullOrEmpty } from '@truescope-web/utils/src/strings';
import './Title.scss';

export default function Title(): ReactElement {
	const { mediaItem } = useAppSelector((state) => state.mediaItem);
	const sourceMediaType = mediaItem.media_type;
	const mediaTypeIcon = getMediaTypeIcon(sourceMediaType);
	const urlIcon = !stringIsNullOrEmpty(mediaItem.source_url) ? getHostedIconUrl(mediaItem.source_url, 60) : null;
	const titleString = joinDefined([mediaItem.source_name, mediaItem.section], ', ');

	return (
		<div className="titleChannel">
			<Inline responsiveWrap={false}>
				<div className="source-image">
					<ImageBadge imgUrl={mediaTypeIcon} alt={sourceMediaType}>
						<Avatar imgSrc={urlIcon} alt={mediaItem.source_url} size="md" initials={mediaItem.short_name} />
					</ImageBadge>
				</div>
				<Typography variant="h5" bold>
					{titleString}
				</Typography>
			</Inline>
		</div>
	);
}
