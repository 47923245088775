import React, { ReactElement, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { KeywordMatches } from 'types/mediaItem';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Tags from '@truescope-web/react/lib/components/widgets/Tags';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/src/arrays';
import './KeywordTags.scss';

const transformKeywords = (keyword_matches: KeywordMatches[]): string[] => {
	return keyword_matches?.map(({ keyword, count }) => `${keyword} (${count})`);
};

export default function KeywordTags(): ReactElement {
	const { mediaItem } = useAppSelector((state) => state.mediaItem);
	const [keywords] = useState(transformKeywords(mediaItem?.keyword_matches));

	if (arrayIsNullOrEmpty(keywords)) {
		return <></>;
	}

	return (
		<Grid item className="keyword-tags">
			<Typography className="title" variant="subtitle">
				Mentions
			</Typography>
			<Tags defaultVariant="blue">{keywords}</Tags>
		</Grid>
	);
}
