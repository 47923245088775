import React, { ReactElement } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';

export default (): ReactElement => {
	return (
		<Grid container>
			<Grid item sm={6}>
				<Grid container>
					<Grid item>
						<Skeleton variant="rectangular" width="100%" height="300px" />
						<Skeleton width="100%" height="60px" />
					</Grid>
					<Grid item>
						<SkeletonWrapper />
					</Grid>
					<Grid item>
						<Inline responsiveWrap={false}>
							<Skeleton width="100px" height="40px" />
							<Skeleton width="100px" height="40px" />
						</Inline>
					</Grid>
				</Grid>
			</Grid>
			<Grid item sm={6}>
				<Grid container>
					<Grid item>
						<Inline responsiveWrap={false}>
							<Skeleton variant="circular" height="45px" width="45px" />
							<div>
								<Skeleton width="120px" height="22px" />
								<Skeleton width="60px" height="22px" />
							</div>
						</Inline>
					</Grid>
					<Grid item>
						<Skeleton width="120px" height="25px" />
						<Skeleton width="470px" height="22px" />
					</Grid>
					<Grid item>
						<Skeleton width="120px" height="25px" />
						<Inline responsiveWrap={false}>
							<Skeleton width="160px" height="22px" />
							<Skeleton width="230px" height="22px" />
							<Skeleton width="100px" height="22px" />
						</Inline>
					</Grid>
					<Grid item>
						<Skeleton width="120px" height="25px" />
						<Skeleton width="320px" height="22px" />
					</Grid>
					<Grid item>
						<Skeleton width="420px" height="25px" />
					</Grid>
					{[0, 1, 2, 3, 4].map((i) => (
						<Grid item key={i}>
							<Inline>
								<Inline>
									<Skeleton width="20px" height="22px" />
									<Skeleton width="220px" height="22px" />
								</Inline>
								<Inline responsiveWrap={false} horizontalAlignment={horizontalAlignment.right}>
									<Skeleton width="40px" height="22px" />
								</Inline>
							</Inline>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};
