import React, { ReactElement } from 'react';
import Typography from '@truescope-web/react/lib/components/layout/Typography';

export default function Footer(): ReactElement {
	return (
		<>
			<Typography variant="subtitle">You are receiving this Media Item because your organisation subscribes to Truescope.</Typography>
			<Typography variant="subtitle">Copyright Truescope Operations Pty Ltd, 2021. All Rights Reserved.</Typography>
		</>
	);
}
