import React, { ReactElement, useCallback, useState } from 'react';
import axios from 'axios';
import ContentItem from 'components/ContentItem';
import Title from 'components/Title';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Theme, useMediaQuery } from '@mui/material';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import TvEyesMediaPlayer from '@truescope-web/react/lib/components/widgets/TvEyesMediaPlayer';
import { isNullOrUndefined } from '@truescope-web/utils/src/objects';
import Content from '../../layout/Content';

export default (): ReactElement => {
	const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const dispatch = useAppDispatch();
	const { mediaItem, isLoading } = useAppSelector((state) => state.mediaItem);
	const [mediaPlayerUrl, setMediaPlayerUrl] = useState('');
	const [isError, setIsError] = useState(false);

	const fetchBroadcastData = useCallback(async () => {
		try {
			const { data } = await axios.post('viewer/v1/podcast', {
				podcastId: mediaItem.supplier_info_keys,
				keywordsToHighlight: mediaItem.keywords_regex,
				highlightIndex: 0,
			});

			const { mediaview_url: mediaUrl } = data;
			setMediaPlayerUrl(mediaUrl);
		} catch (_e) {
			setIsError(true);
		}
	}, [mediaItem, setMediaPlayerUrl, setIsError]);

	React.useEffect(() => {
		setIsError(false);

		if (isNullOrUndefined(mediaItem?.supplier_info_keys)) {
			setIsError(true);
			return;
		}

		fetchBroadcastData();
	}, [dispatch, mediaItem?.supplier_info_keys, fetchBroadcastData]);

	if (isLoading) {
		return <SkeletonWrapper />;
	}

	if (isError) {
		return (
			<section className="podcast">
				<Typography variant="h3">Something went wrong</Typography>
				<Typography variant="subtitle">Unable to load the media player</Typography>
			</section>
		);
	}

	return (
		<Grid container component="section" spacing={5}>
			<Grid item xs={12} sm={6} className="content mobile">
				{isSmDown && (
					<ContentItem>
						<Title />
					</ContentItem>
				)}
				<Grid item className="viewer">
					<section className="podcast">
						<TvEyesMediaPlayer url={mediaPlayerUrl} />
					</section>
				</Grid>
			</Grid>

			<Content />
		</Grid>
	);
};
