import React, { ReactElement } from 'react';
import './Header.scss';

export default function Header(): ReactElement {
	return (
		<div className="logo">
			<img src={`${process.env.PUBLIC_URL}/truescope-logo.png`} alt="Truescope" />
		</div>
	);
}
