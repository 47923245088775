import { combineReducers } from '@reduxjs/toolkit';
import mediaItemReducer from './mediaItem';

export const reducers = {
	mediaItem: mediaItemReducer,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
