import React, { ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router';
import Footer from 'components/Footer';
import Header from 'components/Header';
import TVEyesPodcast from 'components/TVEyesPodcast';
import BroadcastItem from 'layout/BroadcastItem';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ActionType, fetchMediaItemById } from 'store/mediaItem';
import { isBroadcast } from '@truescope-web/utils/lib/mediaTypes';
import { isNullOrUndefined } from '@truescope-web/utils/src/objects';
import MediaItemLoading from './MediaItemLoading';

interface Props {
	type: ActionType;
}

function MediaItem({ type }: Props): ReactElement {
	const { uuid, itemId, encryptedParams } = useParams<{
		uuid?: string;
		itemId?: string;
		encryptedParams?: string;
	}>();

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { isLoading = true, error, mediaItem } = useAppSelector((state) => state.mediaItem);
	const mediaType = mediaItem?.media_type;

	React.useEffect(() => {
		if ((typeof uuid !== 'string' || typeof itemId !== 'string') && typeof encryptedParams !== 'string') {
			navigate('/');
			return;
		}

		dispatch(
			fetchMediaItemById({
				uuid,
				itemId,
				encryptedParams,
				type,
			})
		);
	}, [dispatch, uuid, itemId, navigate, type, encryptedParams]);

	if (error) {
		navigate('/');
		return <></>;
	}

	return (
		<>
			<header>
				<Header />
			</header>
			<main>
				{isLoading && <MediaItemLoading />}
				{!isLoading && !isNullOrUndefined(mediaType) && isBroadcast(mediaType) ? <BroadcastItem /> : <TVEyesPodcast />}
			</main>
			<footer>
				<Footer />
			</footer>
		</>
	);
}

export default MediaItem;
