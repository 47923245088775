import React, { ReactElement } from 'react';
import { useAppSelector } from 'store/hooks';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { buildDateString } from '@truescope-web/utils/src/dates';

export default function DateTime(): ReactElement {
	const { mediaItem } = useAppSelector((state) => state.mediaItem);

	return (
		<div className="date-time">
			<Typography className="title" variant="subtitle">
				Date and time
			</Typography>
			<Typography data-testid="dateTime">{buildDateString(mediaItem?.item_date, mediaItem?.media_type)}</Typography>
		</div>
	);
}
