import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import MediaItem from 'types/mediaItem';
import { deserializeMediaItem } from 'utils/mediaItem';
import { stringIsNullOrEmpty } from '@truescope-web/utils/src/strings';

export interface MediaItemState {
	mediaItem?: MediaItem;
	isLoading: boolean;
	error: boolean;
}

export type ActionType = 'report' | 'mentions' | 'spike' | 'alert';
interface FetchMediaItemByIdArgs {
	uuid?: string;
	itemId?: string;
	encryptedParams?: string;
	type: ActionType;
}

export const initialState: MediaItemState = {
	isLoading: true,
	error: false,
};

export const fetchMediaItemById = createAsyncThunk<MediaItem, FetchMediaItemByIdArgs>(
	'mediaItem/fetchMediaItemById',
	async ({ uuid, itemId, encryptedParams = undefined, type = 'report' }): Promise<MediaItem> => {
		if (!stringIsNullOrEmpty(encryptedParams)) {
			const { data } = await axios.post('viewer/v1/encrypted', { encrypted_params: encryptedParams });

			return deserializeMediaItem(data);
		}

		const { data } = await axios.post(`viewer/v1/${uuid}/${itemId}`, {
			type,
		});

		return deserializeMediaItem(data);
	}
);

export const MediaItemSlice = createSlice({
	name: 'MediaItem',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchMediaItemById.pending, (state) => {
			state.isLoading = true;
			state.error = false;
		});

		builder.addCase(fetchMediaItemById.fulfilled, (state, { payload }) => {
			state.mediaItem = payload;
			state.isLoading = false;
		});

		builder.addCase(fetchMediaItemById.rejected, (state) => {
			state.error = true;
			state.isLoading = false;
			state.mediaItem = undefined;
		});
	},
});

const { reducer } = MediaItemSlice;

export default reducer;
