import React, { ReactElement } from 'react';
import { useAppSelector } from 'store/hooks';
import Syndicates from '@truescope-web/react/lib/components/widgets/SyndicationList';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/src/arrays';
import './SyndicationList.scss';

export default function SyndicationList(): ReactElement {
	const { mediaItem } = useAppSelector((state) => state.mediaItem);

	if (arrayIsNullOrEmpty(mediaItem?.other_syndicated_items)) {
		return <></>;
	}

	return (
		<div className="syndication-list">
			<Syndicates mediaItem={mediaItem} showDividers={false} showLinks={false} />
		</div>
	);
}
