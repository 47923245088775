import React, { ReactElement } from 'react';
import BroadcastViewer from 'components/BroadcastViewer';
import ContentItem from 'components/ContentItem';
import Title from 'components/Title';
import { Theme, useMediaQuery } from '@mui/material';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Content from './Content';

export default (): ReactElement => {
	const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	return (
		<Grid container component="section" spacing={5}>
			<Grid item xs={12} sm={6} className="content mobile">
				{isSmDown && (
					<ContentItem>
						<Title />
					</ContentItem>
				)}
				<Grid item className="viewer">
					<BroadcastViewer />
				</Grid>
			</Grid>

			<Content />
		</Grid>
	);
};
